import React, { useState, useEffect } from "react";
import ButtonMailto from "../../ButtonMailto";
import SectionHeader from "../SectionHeader";
import axios from 'axios';
import "./index.css";

function RequestBooking() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        company: "",
        titleOrRole: "",
        comments: ""
    });

    const validateForm = () => {
        // check that all required fields are non-empty
        // make boxes that are not filled in as red
    }

    const handleSubmit = async () => {
        // TODO: call some validation check function here
        await axios.post('http://localhost:4000/api/bookings', formData);
    }

    useEffect(() => {
        console.log(formData);
    }, [formData]);
    
    return (
        <div>
            <SectionHeader text="CONTACT US"></SectionHeader>
            <div className="section">
                {/* <h2 style={{paddingBottom: "1.5rem"}}>CONTACT US</h2> */}
                <div className="desc-text">
                    <p>
                        To explore how we might work together, please get in touch with us. Fill out the following form or send an email to&nbsp;
                        <ButtonMailto mailto="open6g.otic@northeastern.edu">
                            <p className="email-label"><b>open6g.otic@northeastern.edu</b></p>
                        </ButtonMailto>
                        . We’ll get back to you shortly!
                    </p>
                </div>
            </div>
        </div>
        // <div className="section" style={{paddingTop: "7.5rem"}}>
        //     <div className="booking">
        //         <h2 style={{paddingBottom: "1.5rem"}}>REQUEST A BOOKING</h2>
        //         <p>
        //             To explore how we might work together, please get in touch with us. Fill out the following form or send an email to&nbsp;
        //             <ButtonMailto mailto="open6g.otic@northeastern.edu">
        //                 <p className="email-label">open6g.otic@northeastern.edu</p>
        //             </ButtonMailto>
        //             . We’ll get back to you shortly!
        //         </p>
        //         <form className="custom-form">
        //             <div className="form-row">
        //                 <div className="form-group">
        //                     <h6>First Name*</h6>
        //                     <input
        //                         type="text"
        //                         placeholder="Enter Text"
        //                         className="text-input-field"
        //                         value={formData.firstName}
        //                         onChange={(e) => setFormData({...formData, firstName: e.target.value})}
        //                     />
        //                 </div>
        //                 <div className="form-group">
        //                     <h6>Last Name*</h6>
        //                     <input
        //                         type="text"
        //                         placeholder="Enter Text"
        //                         className="text-input-field"
        //                         value={formData.lastName}
        //                         onChange={(e) => setFormData({...formData, lastName: e.target.value})}
        //                     />
        //                 </div>
        //             </div>
        //             <div className="form-row">
        //                 <div className="form-group">
        //                     <h6>Email*</h6>
        //                     <input
        //                         type="email"
        //                         placeholder="Enter Email"
        //                         className="text-input-field"
        //                         value={formData.email}
        //                         onChange={(e) => setFormData({...formData, email: e.target.value})}
        //                     />
        //                 </div>
        //                 <div className="form-group">
        //                     <h6>Phone Number</h6>
        //                     <input
        //                         type="text"
        //                         placeholder="Enter Phone Number"
        //                         className="text-input-field"
        //                         value={formData.phoneNumber}
        //                         onChange={(e) => setFormData({...formData, phoneNumber: e.target.value})}
        //                     />
        //                 </div>
        //             </div>
        //             <div className="form-row">
        //                 <div className="form-group">
        //                     <h6>Company*</h6>
        //                     <input
        //                         type="text"
        //                         placeholder="Enter Text"
        //                         className="text-input-field"
        //                         value={formData.company}
        //                         onChange={(e) => setFormData({...formData, company: e.target.value})}
        //                     />
        //                 </div>
        //                 <div className="form-group">
        //                     <h6>Title or Role*</h6>
        //                     <input
        //                         type="text"
        //                         placeholder="Enter Text"
        //                         className="text-input-field"
        //                         value={formData.titleOrRole}
        //                         onChange={(e) => setFormData({...formData, titleOrRole: e.target.value})}
        //                     />
        //                 </div>
        //             </div>
        //             <div className="form-group">
        //                 <h6 style={{textAlign: "left"}}>Tell us about your interests. How can we help?</h6>
        //                 <textarea
        //                     placeholder="Enter Text"
        //                     className="text-input-field"
        //                     value={formData.comments}
        //                     onChange={(e) => setFormData({...formData, comments: e.target.value})}
        //                     />
        //             </div>
        //             <button 
        //                 type="submit" 
        //                 className="lg-btn"
        //                 onClick={handleSubmit}>
        //                 <h6>ENGAGE WITH US</h6>
        //             </button>
        //         </form>
        //     </div>
        // </div>
    );
}

export default RequestBooking;