import React from "react";
import { Link } from "react-router-dom";
import SectionHeader from "../Components/SectionHeader";

function Equipment() {
    return (
        <div>
            <SectionHeader text="TESTING EQUIPMENT PORTFOLIO"></SectionHeader>
                <div className="section">
                    <div className="portfolio">
                        <div className="portfolio-text">
                            <p id="left">The new Open6G OTIC provides testing capabilities for the validation of end-to-end control logic; AI and machine learning (ML) solutions for the design, development, and operations of open and programmable cellular networks; and interoperability and performance testing across heterogeneous vendors and network components. The OTIC adopts a system-level approach, providing testing devices and services that can be used to validate disaggregated base stations, RAN Intelligent Controllers (RICs), including custom applications (i.e., xApps and rApps). These can be tested them against different kinds of RAN implementations (including commercial and software-defined products), in emulated or over-the-air environments.</p>
                            <p id="left">The Open6G OTIC is furnished with state-of-the-art testing equipment from Keysight and Viavi, including the Keysight E6464A Multi Transceiver RF Test Set (MTRX) and Time Sync Analyzer for massive MIMO and MIMO radio unit conformance testing, the UeSIM, RuSIM, DuSIM, CuSIM, and CoreSIM solutions for RU, DU, CU conformance, integration, and end-to-end testing, as well as the Viavi TeraVM RIC Test for testing of RAN intelligent Controller applications.</p>
                        </div>
                        <img src="/images/equipment.png" className="img-fluid" alt="equipment"/>
                    </div>
                
                <div className="equipment">
                    <div className="equipment-container">
                        <Link to="https://www.keysight.com/us/en/product/P8822S/rusim-over-o-ran-fronthaul.html" 
                        target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="equipment-section">
                                <img src="/images/rusim.png" className="img-fluid e-pic" style={{marginBottom: "1.5rem"}} alt="rusim"/>
                                <h6>KEYSIGHT RuSIM FOR DU R&D AND WG4 CONFORMANCE</h6>
                                <ul className="bullet">
                                    <li>Support for FR1 and FR2</li>
                                    <li>Currently up to 4 MIMO Layers, extendable to 8 upon request</li>
                                    <li>Up to 16 simulated UEs, extendable upon request</li>
                                </ul>
                            </div>
                        </Link>
                        <Link to="https://www.keysight.com/us/en/product/P8826S/dusim-o-cu-midhaul-solutions.html" 
                        target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="equipment-section">
                                <img src="/images/simulator.png" className="img-fluid e-pic" style={{marginBottom: "1.5rem"}} alt="simulator"/>
                                <h6>KEYSIGHT DuSIM, CuSIM, AND CoreSIM</h6>
                                <ul className="bullet">
                                    <li>Wrap around testing for softwarized CU and DU solutions</li>
                                    <li>Indicating performance or other E2E functionalities</li>
                                    <li>Awarding O-RAN E2E badges to all products in the E2E system or subsystem</li>
                                </ul>
                            </div>
                        </Link>
                        <Link to="https://www.keysight.com/us/en/products/network-test/network-test-hardware/time-sync-analyzer.html" 
                        target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="equipment-section">
                                <img src="/images/analyzer.png" className="img-fluid e-pic" style={{marginBottom: "1.5rem"}} alt="analzer"/>
                                <h6>KEYSIGHT TIME SYNC ANALYZER FOR RU R&D AND WG4 CONFORMANCE</h6>
                                <ul className="bullet">
                                    <li>S-Plane Testing</li>
                                    <li>Up to 4x 25GbE FH Traffic</li>
                                    <li>Support for FR1 and FR2</li>
                                </ul>
                            </div>
                        </Link>
                        <Link to="https://www.keysight.com/us/en/products/network-test/5g-nr-base-station-test/multiport-rf-transceivers.html" 
                        target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="equipment-section">
                                <img src="/images/mtrx.png" className="img-fluid e-pic" style={{marginBottom: "1.5rem"}} alt="mtrx"/>
                                <h6>KEYSIGHT MTRX FOR RU CONDUCTED AND RADIATED WG4 CONFORMANCE</h6>
                                <ul className="bullet">
                                    <li>Up to 64 Antennas</li>
                                    <li>FR1</li>
                                    <li>Massive MIMO and Beamforming</li>
                                </ul>
                            </div>
                        </Link>
                        <Link to="https://www.keysight.com/us/en/products/network-test/network-test-hardware/network-emulator-3.html" 
                        target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="equipment-section">
                                <img src="/images/emulator.png" className="img-fluid e-pic" style={{marginBottom: "1.5rem"}} alt="emulator"/>
                                <h6>KEYSIGHT NETWORK EMULATOR 3</h6>
                                <ul className="bullet">
                                    <li>Impairment generation and Delay introduction up to 100 Gbps</li>
                                    <li>Used to simulate diverse network conditions in IOT and E2E badging</li>
                                </ul>
                            </div>
                        </Link>
                        <Link to="https://www.keysight.com/us/en/product/P8800S/uesim-ue-emulation-ran-solutions.html" 
                        target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="equipment-section">
                                <img src="/images/sdr.png" className="img-fluid e-pic" style={{marginBottom: "1.5rem"}} alt="sdr"/>
                                <h6>KEYSIGHT UE SIMULATOR WITH SDR</h6>
                                <ul className="bullet">
                                    <li>Simulation of up to 64 UEs over RF, both conducted and radiated</li>
                                    <li>4 TX and 4 RX Antennas</li>
                                    <li>4 MIMO layers</li>
                                </ul>
                            </div>
                        </Link>
                        <Link to="https://www.keysight.com/us/en/products/wireless-analyzers/wavejudge-wireless-analyzer-solutions.html" 
                        target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="equipment-section">
                                <img src="/images/wavejudge.png" className="img-fluid e-pic" style={{marginBottom: "1.5rem"}} alt="wavejudge"/>
                                <h6>KEYSIGHT WAVEJUDGE</h6>
                                <ul className="bullet">
                                    <li>O-FH dissection</li>
                                    <li>OTA 5GNR decoding and troubleshooting</li>
                                </ul>
                            </div>
                        </Link>
                        <Link to="https://www.keysight.com/us/en/products/oscilloscopes/infiniium-real-time-oscilloscopes/infiniium-mxr-series-real-time-oscilloscopes.html" 
                        target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="equipment-section">
                                <img src="/images/mxr.png" className="img-fluid e-pic" style={{marginBottom: "1.5rem"}} alt="mxr"/>
                                <h6>KEYSIGHT MXR INFINIIUM OSCILLOSCOPES</h6>
                                <ul className="bullet">
                                    <li>5GNR Decoding</li>
                                    <li>Vector Signal Analysis</li>
                                </ul>
                            </div>
                        </Link>
                        <Link to="https://www.viavisolutions.com/en-us/products/teravm-ric-test" 
                        target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="equipment-section">
                                <img src="/images/teravm.png" className="img-fluid e-pic" style={{marginBottom: "1.5rem"}} alt="teravm"/>
                                <h6>VIAVI TERAVM RIC TEST</h6>
                                <ul className="bullet">
                                    <li>Up to 64 Antennas</li>
                                    <li>FR1</li>
                                    <li>Massive MIMO and Beamforming</li>
                                </ul>
                            </div>
                        </Link>
                        <Link to="https://airspan.com/software/vran-openrange/" 
                        target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="equipment-section">
                                <img src="/images/openrange.png" className="img-fluid e-pic" style={{marginBottom: "1.5rem"}} alt="openrange"/>
                                <h6>AIRSPAN OPEN RANGE SOLUTION WITH AIRVELOCITY 2700 RU</h6>
                                <ul className="bullet">
                                    <li>Two band N48 RUs that provide private 5G capabilities in the Open6G space</li>
                                </ul>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Equipment;