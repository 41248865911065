import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import RequestBooking from "../Components/RequestBooking";
import "./index.css";
import Approach from "./Approach";
import ResearchOpen6G from "./ResearchOpen6G";

function Services() {
    const { hash } = useLocation();

    window.scrollTo(0, 0);

    useEffect(() => {      
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);

    return (
        <div style={{paddingTop: "3.612rem"}}>
            <div className="about-container">
                <div className="left-container">
                    <img src="/images/drone.jpg" className="img-fluid no-border-radius" alt="colosseum" />
                    <div className="about-overlay">
                        <h2>OPEN6G RESEARCH AND DEVELOPMENT</h2> 
                        {/*<p>The North American OTIC in the Boston area (Open6G OTIC) builds on testbeds and facilities hosted in the Open6G space of the Institute for the Wireless Internet of Things (WloT) at Northeastern University, member of the O-RAN Alliance since 2020. The Open6G OTIC is a one-stop shop for Open RAN innovation. It serves as a hub for the development and testing of next-generation wireless networks utilizing Open RAN technology and Artificial Intelligence, offering a comprehensive range of testing services that facilitates compliance, interoperability, performance, and functional testing for Open RAN products.</p>*/}
                        <p>The Open6G center is home to world-leading research on <b>end-to-end, programmable, intelligent, and softwarized Open RAN systems</b>. The team at Open6G has demonstrated for the first time the gain that dynamic slicing and load-balancing driven by DRL agents on O-RAN xApps. The team has also shown key results in the areas of intelligent spectrum sensing and sharing, real-time extensions of the O-RAN architecture, and intelligent control and orchestration for Open RAN.</p>
                    </div>
                </div>
                <div className="right-container-mod">
                    {/*<h2>OPEN6G: ONE-STOP SHOP FOR OPEN RAN INNOVATION</h2>*/}
                    <svg width="100%" height="0.1" viewBox="0 0 427 0.1" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
                        <line y1="0.05" x1="0" x2="100%" y2="0.05" stroke="#FFFFFF"/>
                    </svg>
                    <div className="top-icon-row ee-row">
                        <img src="/images/leaf.png" className="no-border-radius" alt="leaf" style={{maxHeight: "2.5rem", width: "auto"}}/>
                        <Link to="research-achievements/#tenoran" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <h4>Energy Efficiency</h4>
                        </Link>
                    </div>
                    <div className="top-icon-row dt-row">
                        <img src="images/digital-twin.png" alt="digital twin" style={{maxHeight: "2.5rem", width: "auto"}}/>
                        <Link to="research-achievements/#digiran" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <h4>Digital Twinning</h4>
                        </Link>
                    </div>
                    <div className="top-icon-row">
                        <img src="images/loop.png" alt="loop" style={{maxHeight: "2.5rem", width: "auto"}}/>
                        <Link to="research-achievements/#autoran" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <h4>End-to-End Automation</h4>
                        </Link>
                    </div>
                    <div className="top-icon-row eeps-row">
                        <img src="images/layer.png" alt="stack" style={{maxHeight: "2.5rem", width: "auto"}}/>
                        <Link to="research-achievements/#ousd" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <h4>End-to-End Protocol Stacks</h4>
                        </Link>
                    </div>
                    <div className="top-icon-row ioc-row">
                        <img src="images/ai.png" alt="ai networks" style={{maxHeight: "2.5rem", width: "auto"}}/>
                        <Link to="research-achievements/#oran" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <h4>Intelligent O-RAN Control</h4>
                        </Link>
                    </div>
                    <svg width="100%" height="0.1" viewBox="0 0 427 0.1" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
                        <line y1="0.05" x1="0" x2="100%" y2="0.05" stroke="#FFFFFF"/>
                    </svg>
                </div>
            </div>
            <div id="ResearchOpen6G">
                <ResearchOpen6G/>
            </div>
            <div id="Approach">
                <Approach/>
            </div>
            <div id="RequestBooking">
                <RequestBooking/>
            </div>
        </div>
    );
}

export default Services;