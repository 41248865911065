import SectionHeader from "../Components/SectionHeader";
import "./index.css";

function SystemIntegration() {
    return (
        <div className="reduce-top-later">
            <SectionHeader text="SYSTEM INTEGRATION SERVICES"></SectionHeader>
            <div className="section">
                <div className="integration-row">
                    <div className="integration-section">
                        <h4>Interoperability</h4>
                        <p id="center">Ensuring that equipment from different vendors can communicate effectively and function together as intended, in pairs or in complex end-to-end environments.</p>
                    </div>
                    <div className="integration-section">
                        <h4>Customization & Configuration </h4>
                        <p id="center">Adapting system settings to suite unique network infrastructures and operational needs with AI-driven solutions.</p>
                    </div>
                    <div className="integration-section">
                        <h4>Performance Optimization</h4>
                        <p id="center">Fine-tuning system parameters and algorithms to enhance network efficiency, reliability, and QoS, maximizing the potential of Open RAN deployments.</p>
                    </div>
                    <div className="integration-section">
                        <h4>Energy Efficiency Optimization</h4>
                        <p id="center">Integrating comprehensive energy monitoring, management, and optimization routines for wireless hardware and software.</p>
                    </div>
                    <div className="integration-section">
                        <h4>Training & Knowledge Transfer</h4>
                        <p id="center">Provide training and knowledge transfer sessions to stakeholders, delivered by world-leading experts in the area of wireless, AI, and Open RAN.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SystemIntegration;