import React from 'react';
import { Link } from 'react-router-dom';
import Modal from '../Modal';
import "./index.css";
import Button from "../../Components/Button";

function Menu() {
    const [open, setOpen] = React.useState<string | null>(null);

    const handleClose = () => {
        setOpen(null);
    };

    const handleOpen = (modalId: string) => {
        setOpen(modalId);
    };

    return (
        <>
            <div className="menu-row">
                <div className={`menu-container ${open ? 'shifted' : ''}`}>
                    <div className="menu-content">
                        <div className="horizontal">
                            <h2 style={{paddingRight: "1.5rem"}}>SERVICES</h2>
                            <Link to="/OTIC/#Certifications">
                                <Button buttonType="button-primary" textStyle="subtitle">LEARN MORE</Button>
                            </Link>
                        </div>
                        <div className="bullets">
                            <ul>
                                <li>
                                    <button type="button" className="menu-button" onClick={() => handleOpen('testing')}>
                                        <h6 style={{color: "inherit"}}>Testing as a Service</h6>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="menu-button" onClick={() => handleOpen('lab')}>
                                        <h6 style={{color: "inherit"}}>Lab as a Service</h6>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="menu-button" onClick={() => handleOpen('certification')}>
                                        <h6 style={{textAlign: "left", paddingBottom: "0.75rem", color: "inherit"}}>O-RAN Certification and <br/>Badging</h6>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="menu-button" onClick={() => handleOpen('plugfest')}>
                                        <h6 style={{color: "inherit"}}>O-RAN PlugFest</h6>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="menu-button" onClick={() => handleOpen('aiml')}>
                                        <h6 style={{color: "inherit"}}>AI/ML Testing</h6>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="menu-button" onClick={() => handleOpen('integration')}>
                                        <h6 style={{color: "inherit"}}>System Integration</h6>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div style={{marginTop: "3rem"}}>
                            <div className="testing-container" style={{alignItems: "flex-start"}}>
                                <h2>TESTING<br/>EQUIPMENT</h2>
                            </div>
                            <div className="equipment-images">
                                <img src="/images/rusim.png" className="e-img" alt="rusim"/>
                                <img src="/images/mxr.png" className="e-img" alt="mxr"/>
                                <img src="/images/teravm.png" className="e-img" alt="teravm"/>
                            </div>
                            <Link to="/OTIC/#Equipment">
                                <Button buttonType="button-primary" textStyle="subtitle">LEARN MORE</Button>
                            </Link>
                        </div>
                    </div>
                </div>

                <Modal isOpen={open === 'certification'} onClose={handleClose}>
                    <h4 style={{paddingBottom: "0.5rem"}}>O-RAN Certification and Badging</h4>
                    <ul className="shorter-bullets">
                        <li><p className="subtitle" id="left">Conformance Certificates</p></li>
                        <li id="indent-less"><p className="subtitle" id="left">Certify DUT conformance to O-RAN WG4 specification</p></li>
                        <li id="indent-less"><p className="subtitle" id="left">RU (64T64R), DU</p></li>
                        <li><p className="subtitle" id="left">InterOperability Testing Badges</p></li>
                        <li id="indent-less"><p className="subtitle" id="left">Assess interoperability according to O-RAN WG4 and WG5 specifications</p></li>
                        <li id="double-indent-less"><p className="subtitle" id="left">RU, CU, DU</p></li>
                        <li><p className="subtitle" id="left">End to End Badges</p></li>
                        <li id="indent-less"><p className="subtitle" id="left">Evaluate O-RAN CUs, DUs, and RUs for end-to-end performance and security according to O-RAN TIFG specifications</p></li>
                    </ul>
                    <Link to="/OTIC/#Certifications">
                        <div style={{paddingTop: "0.5rem"}}>
                            <Button buttonType="button-primary" textStyle="subtitle">LEARN MORE</Button>   
                        </div>
                    </Link>
                </Modal>

                <Modal isOpen={open === 'testing'} onClose={handleClose}>
                    <div style={{alignItems: "flex-start"}}>
                        <h4 style={{paddingBottom: "0.5rem"}}>Testing as a Service</h4>
                        <ul className="shorter-bullets">
                            <li><p className="subtitle" id="left">Conformance</p></li>
                            <li><p className="subtitle" id="left">End-to-End (E2E)</p></li>
                            <li><p className="subtitle" id="left">Interoperability</p></li>
                            <li><p className="subtitle" id="left">RIC</p></li>
                        </ul>
                        <Link to="/OTIC/#Certifications">
                            <div style={{paddingTop: "0.5rem"}}>
                                <Button buttonType="button-primary" textStyle="subtitle">LEARN MORE</Button>   
                            </div>
                        </Link>
                    </div>
                </Modal>

                <Modal isOpen={open === 'lab'} onClose={handleClose}>
                    <div style={{alignItems: "flex-start"}}>
                        <h4 style={{paddingBottom: "0.5rem"}}>Lab as a Service</h4>
                        <p className="subtitle" id="left" style={{paddingBottom: "1rem"}}>
                            The Open6G OTIC provides interoperability and integration services for our partners, as well as rack and office space to deploy equipment for integration and testing with complex, multi-vendor stack and environments.
                        </p>
                        <Link to="/Services">
                            <div style={{paddingTop: "0.5rem"}}>
                                <Button buttonType="button-primary" textStyle="subtitle">LEARN MORE</Button>   
                            </div>
                        </Link>
                    </div>
                </Modal>

                <Modal isOpen={open === 'plugfest'} onClose={handleClose}>
                    <div style={{alignItems: "flex-start"}}>
                        <h4 style={{paddingBottom: "0.5rem"}}>O-RAN PlugFests</h4>
                        <p className="subtitle" id="left" style={{paddingBottom: "1rem"}}>
                            The Open6G OTIC hosts O-RAN PlugFests with multiple national and international partners, to foster an ecosystem that is conducive to consistent and repeatable testing of O-RAN products. We provide rack space, an ready-to-go environment for the deployment of software and hardware, and expertise in rapidly developing end-to-end demos and prototypes. The Open6G space in Burlington, MA has hosted final readout events convening tens of participants to discuss O-RAN integration and testing.
                        </p>
                        <Link to="/OTIC/#Plugfests">
                            <div style={{paddingTop: "0.5rem"}}>
                                <Button buttonType="button-primary" textStyle="subtitle">LEARN MORE</Button>   
                            </div>
                        </Link>
                    </div>
                </Modal>

                <Modal isOpen={open === 'aiml'} onClose={handleClose}>
                    <div style={{alignItems: "flex-start"}}>
                        <h4 style={{paddingBottom: "0.5rem"}}>AI/ML Testing</h4>
                        <p className="subtitle" id="left" style={{paddingBottom: "1rem"}}>
                            The Colosseum's digital twin capabilities are critical for advancing the testing and eventual certification of AI-driven solutions within the Open RAN ecosystem. Leveraging the extensive research and development expertise accumulated by the Open6G team in AI/ML for wireless technologies, these capabilities are positioned to accelerate and enhance AI testing within Open RAN environments.
                        </p>
                        <Link to="/Research/#AI">
                            <div style={{paddingTop: "0.5rem"}}>
                                <Button buttonType="button-primary" textStyle="subtitle">LEARN MORE</Button>   
                            </div>
                        </Link>
                    </div>
                </Modal>

                <Modal isOpen={open === 'integration'} onClose={handleClose}>
                    <div className="system-integration">
                        <h4>System Integration</h4>
                        <p className="subtitle" id="left" style={{paddingBottom: "0.5rem"}}>
                            At Open6G OTIC, we specialize in optimizing Open RAN deployments through seamless interoperability, AI-driven customization, and performance optimization. Our integrated fault management and comprehensive documentation ensure efficient network operations and quick issue resolution.
                        </p>
                        <Link to="/Services/#SystemIntegration">
                            <Button buttonType="button-primary" textStyle="subtitle">LEARN MORE</Button>
                        </Link>
                    </div>
                </Modal>
            </div>
        </>
    );
}

export default Menu;