// import SectionHeader from "../Components/SectionHeader";
import "./index.css";

function Testing() {
    return (
        <div className="testing-section">
            <div className="section" style={{paddingTop: "3.611rem"}}>
            {/*<SectionHeader text="TESTING SERVICES"></SectionHeader>*/}
            {/*<div className="section">*/}
                <div className="tests-container">
                    <div className="tests-section">
                        <img src="/images/office.jpg" className="img-fluid t-img" alt="office"/>
                        <h4 id="left">Conformance</h4>
                        <ul className="bullet">
                            <li>Verifying that products and interfaces are compliant to the O-RAN specifications</li>
                            <li>Awarding O-RAN certificates to products for at least one O-RAN interface</li>
                        </ul>
                    </div>
                    <div className="tests-section">
                        <img src="/images/engineering.png" className="img-fluid t-img" alt="wire-display"/>
                        <h4>Interoperability</h4>
                        <ul className="bullet">
                            <li>Proving interoperability of a pair of products connected via O-RAN interfaces</li>
                            <li>Awarding O-RAN IOT badges to both products in these pairs</li>
                        </ul>
                    </div>
                    <div className="tests-section">
                        <img src="/images/hands.jpg" className="img-fluid t-img" alt="hands"/>
                        <h4>End-to-End (E2E)</h4>
                        <ul className="bullet">
                            <li>Demonstrating and validating that E2E systems or subsystems meet minimum requirements on functionality and security</li>
                            <li>Awarding O-RAN E2E badges to all products in the E2E system or subsystem</li>
                        </ul>
                    </div>
                    <div className="tests-section">
                        <img src="/images/wire-display.png" className="img-fluid t-img" alt="engineering"/>
                        <h4>RIC</h4>
                        <ul className="bullet">
                            <li>Providing real-world, emulated, and simulated RAN environments that can be used to test and integrated RAN Intelligent Controllers (RICs), xApps, and rApps</li>
                        </ul>
                    </div>
                </div>
            {/*</div>*/}
            </div>
        </div>
    );
}

export default Testing;