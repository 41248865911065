import SectionHeader from "../Components/SectionHeader";
import Carousel from "../Components/Carousel";

function Plugfests() {
    const images = [
        '/images/carousel-1.jpg',
        '/images/carousel-3.png',
        '/images/carousel-4.png',
        '/images/carousel-5.png',
        '/images/carousel-6.png',
        '/images/carousel-7.png',
    ];
    
    return (
        <div>
            <SectionHeader text="O-RAN PLUGFESTS AT THE OPEN6G OTIC"></SectionHeader>
            <div className="section">
                <p style={{ textAlign: "left" }}>The Open6G OTIC hosts O-RAN PlugFests with multiple national and international partners, to foster an ecosystem that is conducive to consistent and repeatable testing of O-RAN products. We provide rack space, an ready-to-go environment for the deployment of software and hardware, and expertise in rapidly developing end-to-end demos and prototypes. The Open6G space in Burlington, MA has hosted final readout events convening tens of participants to discuss O-RAN integration and testing.</p>
            </div>
            <Carousel images={images} interval={3000}/>
        </div>
    );
}

export default Plugfests;