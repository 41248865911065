import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { useLocation } from "react-router-dom";
import ButtonMailto from "../ButtonMailto";
import "./index.css";

function Footer() {
    const homeLinks = [
        { label: "FocusAreas", title: "Focus Areas" },
        { label: "KeyComponents", title: "Open6G Key Components" },
        { label: "Partners", title: "Partners" },
        { label: "Locations", title: "Locations" },
        { label: "News", title: "Latest News & Updates" }
    ];

    const testingLinks = [
        { label: "SystemIntegration", title: "System Integration Services" },
        { label: "Benefits", title: "Service Benefits" }
    ];

    const { pathname } = useLocation();

    return (
        <div>
            <div className="footer">
                <div className="footer-section">
                    <h4>Open6G O-RAN Testing and Integration Center</h4>
                </div>
                <div className="footer-section">
                    <h5>ABOUT</h5>
                    {homeLinks.map((link, index) => (
                        <p key={index} className={pathname === `/#${link.label}` ? 'active' : ''}>
                            <Link to={`/Home/#${link.label}`}>
                                <span className="subtitle">{link.title}</span>
                            </Link>
                        </p>
                    ))}
                    <p className={pathname === `/#ResearchOpen6G` ? 'active' : ''}>
                        <Link to={`/Research/#ResearchOpen6G`}>
                            <span className="subtitle">Open6G Research & Development Achievements</span>
                        </Link>
                    </p>
                    <p className={pathname === `/#Approach` ? 'active' : ''}>
                        <Link to={`/Research/#Approach`}>
                            <span className="subtitle">Research & Development Approach</span>
                        </Link>
                    </p>
                    <p>
                        <Link to="https://www.northeastern.edu/privacy-information/">
                            <span className="subtitle">Northeastern Privacy Policy</span>
                        </Link>
                    </p>
                </div>
                <div className="footer-section">
                    <h5>OTIC</h5>
                    <p className={pathname === `/#Testing` ? 'active' : ''}>
                        <Link to={`/Services/#Testing`}>
                            <span className="subtitle">Open6G OTIC Testing</span>
                        </Link>
                    </p>
                    <p className={pathname === `/#Testimonials` ? 'active' : ''}>
                        <Link to={`/OTIC/#Testimonials`}>
                            <span className="subtitle">Client Testimonials & Case Studies</span>
                        </Link>
                    </p>
                    <p className={pathname === `/#Equipment` ? 'active' : ''}>
                        <Link to={`/OTIC/#Equipment`}>
                            <span className="subtitle">Testing Equipment Portfolio</span>
                        </Link>
                    </p>
                    <p className={pathname === `/#Plugfests` ? 'active' : ''}>
                        <Link to={`/OTIC/#Plugfests`}>
                            <span className="subtitle">O-RAN PlugFests at the Open6G OTIC</span>
                        </Link>
                    </p>
                </div>
                <div className="footer-section">
                    <h5>SERVICES</h5>
                    {testingLinks.map((link, index) => (
                        <p key={index} className={pathname === `/#${link.label}` ? 'active' : ''}>
                            <Link to={`/Services/#${link.label}`}>
                                <span className="subtitle">{link.title}</span>
                            </Link>
                        </p>
                    ))}
                </div>
                <div className="footer-section">
                    <h5>LOCATIONS</h5>
                    <Link to="https://www.google.com/maps/place/805+Columbus+Ave,+Boston,+MA+02120/@42.3377088,-71.0895858,17z/data=!3m1!4b1!4m6!3m5!1s0x89e37a22a3a9ffa3:0x4fc7d96616e5f289!8m2!3d42.3377049!4d-71.0870109!16s%2Fg%2F11cnddhs8b?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D"
                    target="_blank" rel="noopener noreferrer">
                        <div className="icon-container" style={{alignItems: "flex-start"}}>
                            <img src="/images/icons/location.svg" alt="location"/>
                            <p className="subtitle">815 Columbus Ave, Boston, MA 02120</p>
                        </div>
                    </Link>
                    <Link to="https://www.google.com/maps/place/147+S+Bedford+St+Building+5,+Burlington,+MA+01803/@42.4778283,-71.1953377,17z/data=!3m1!4b1!4m5!3m4!1s0x89e39e2785e23263:0xa0f369a83f59d436!8m2!3d42.4778244!4d-71.1927628!5m1!1e1?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D"
                    target="_blank" rel="noopener noreferrer">
                        <div className="icon-container" style={{alignItems: "flex-start"}}>
                            <img src="/images/icons/location.svg" alt="location"/>
                            <p className="subtitle">147 S Bedford St, Building 5, Burlington, MA 01803</p>
                        </div>
                    </Link>
                </div>
                <div className="footer-section">
                    <h5>CONNECT WITH US</h5>
                    <ButtonMailto mailto="open6g.otic@northeastern.edu">
                        <div className="icon-container">
                            <img src="/images/icons/email.svg" alt="email"/>
                            <p className="subtitle email">open6g.otic@northeastern.edu</p>
                        </div>
                    </ButtonMailto>

                    <Link to={`/Home/#RequestBooking`}>
                        <div className="icon-container">
                            <img src="/images/icons/pen.svg" alt="pen"></img>
                            <p className={pathname === `/#RequestBooking` ? 'active' : ''}>
                                <p className="subtitle">Contact Us</p>
                            </p>
                        </div>
                    </Link>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", paddingTop: "1.5rem"}}>
                        <Link to="https://www.linkedin.com/company/institute-for-the-wireless-internet-of-things" target="_blank" rel="noopener noreferrer">
                            <img src="/images/icons/linkedin.svg" alt="linkedin"></img>
                        </Link>
                        <Link to="https://twitter.com/wiot_neu" target="_blank" rel="noopener noreferrer">
                            <img src="/images/icons/x.svg" alt="x"></img>
                        </Link>
                        <Link to="https://www.instagram.com/wiot_neu/" target="_blank" rel="noopener noreferrer">
                            <img src="/images/icons/instagram.svg" alt="instagram"></img>
                        </Link>
                        <Link to="https://www.youtube.com/@wiot_neu" target="_blank" rel="noopener noreferrer">
                            <img src="/images/icons/youtube.svg" alt="youtube"></img>
                        </Link>
                    </div>
                </div>
            </div>
            <p className="callout acknowledgement">
                The Open6G project is partially supported by the Office of the Under Secretary of Defense for Research and Engineering (OUSD(R&E)) through Army Research Laboratory Cooperative Agreement Number W911NF-24-2-0065, the National Telecommunications and Information Administration (NTIA)’s Public Wireless Supply Chain Innovation Fund (PWSCIF) under Awards No. 25-60-IF002, 25-60-IF011, and 25-60-IF054, the Massachusetts Technology Collaborative, and the O-RAN ALLIANCE. The views and conclusions contained in the documents and information published on this website are those of the authors and should not be interpreted as representing the official policies, either expressed or implied, of the Army Research Laboratory or the U.S. Government. The U.S. Government is authorized to reproduce and distribute reprints for Government purposes notwithstanding any copyright notation herein.
            </p>
        </div>
    );
}

export default Footer;