import SectionHeader from "../Components/SectionHeader";
import Testing from "./Testing";

function Certifications() {
    return (
        <div style={{paddingTop: "6rem"}}>
            <SectionHeader text="OPEN6G OTIC TESTING"></SectionHeader>
            <div className="section">
                <div className="cert-container">
                    <img src="/images/OTIC_logo_crop.jpg" className="img-fluid" alt="otic"/>
                    <h5>The Open6G OTIC can test your equipment for conformance with the O-RAN specifications, interoperability across components or vendors, and end-to-end functionalities.</h5>
                </div>
            </div>
            <div id="Testing">
                <Testing/>
            </div>
        </div>
    );
}

export default Certifications;