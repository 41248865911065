import React from "react";
import SectionHeader from "../Components/SectionHeader";

function Benefits() {
    return (
        <div>
            <SectionHeader text="SERVICE BENEFITS"></SectionHeader>
            <div className="section">
                <div className="benefits-container">
                    <div className="benefits-sections">
                        <div>
                            <h4 style={{paddingBottom: "0.5rem"}}>Partnerships and Ecosystem</h4>
                            <p>Open6G is at the center of a rich and vibrant Open RAN and wireless ecosystem, focused on innovation through a system level approach. Our partners understand the value of collaboration and can identify the most approriate assets and solutions to efficiently deliver innovative Open RAN products and systems. Tech Mahindra, a leading global provider of technology consulting and digital solutions to enterprises across industries, has recently signed a Memorandum of Understanding (MoU) with Open6G to accelerate innovation in Open Radio Access Networks (O-RAN) and 6G connectivity.</p>
                        </div>
                        <div>
                            <h4 style={{paddingBottom: "0.5rem"}}>AI-Driven Network Optimization</h4>
                            <p>Enhance your network's efficiency with our AI-driven optimization services. We use advanced algorithms to analyze data in real-time, allowing for proactive issue resolution and dynamic performance adjustments. This approach improves reliability, reduces costs, and ensures optimal resource use in Open RAN environments.</p>
                        </div>
                    </div>
                    <div className="b-img">
                        <img src="/images/colored-wires.jpg" className="img-fluid" alt="colored-wires"/>
                    </div>
                    <div className="benefits-sections">
                    <div>
                        <h4 style={{paddingBottom: "0.5rem"}}>Comprehensive Open RAN Approach</h4>
                            <p>Our comprehensive approach to Open RAN systems and development ensures your products will be integrated meeting industry standards through thorough conformance, interoperability, and performance evaluations. We verify adherence to specifications, test integration with other components, and assess performance under various conditions. This detailed testing process ensures your solutions are reliable and ready for deployment.</p>
                        </div>
                        <div>
                            <h4 style={{paddingBottom: "0.5rem"}}>Industry-Leading Standards Compliance</h4>
                            <p>Our standards compliance services ensure your Open RAN solutions adhere to O-RAN Alliance and global telecommunications standards. We verify that your products meet rigorous specifications and are interoperable with other technologies. This compliance supports reliable, efficient deployments and minimizes risks.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Benefits;