import React from "react";
import { Link } from "react-router-dom";
import SectionHeader from "../Components/SectionHeader";
import lights from "./blinking-lights.gif";
import "./index.css";

function Approach() {
    return (
        <div>
            <SectionHeader text="RESEARCH & DEVELOPMENT APPROACH"></SectionHeader>
            <div className="section">
                <div className="research-container">
                    <div style={{display: "flex", flexDirection: "column", gap: "1rem", alignItems: "flex-start"}}>
                        <p id="left">Our research and development efforts are at the forefront of technological advancement, driven by a commitment to exploring and harnessing the latest innovations in science and engineering. We investigate cutting-edge technologies and emerging trends, focusing on how these can be transformed into <b>practical, real-world solutions for next-generation wireless systems</b>. Our approach is rooted in fundamental and algorithmic research that transition to systems and prototyping as solutions mature.</p>
                        <p id="left">We strive to translate complex ideas into tangible applications and systems that enhance operational efficiency and address pressing challenges in wireless systems. Our team of skilled researchers, engineers, and developers works collaboratively to refine these concepts through a series of iterative tests and validations, ensuring that each solution meets the highest standards of performance and quality.</p>
                        <p id="left">In addition to our internal efforts, we actively engage with <Link to="/OTIC/#Partners" style={{color: "var(--color-accent-dark)", textDecoration: "none"}}><b className="bolded">industry partners and stakeholders</b></Link> to gain insights and feedback, which helps us to continuously improve and adapt our technologies. This collaborative approach allows us to stay ahead of industry trends and contribute to the development of new standards and best practices.</p>
                        <p id="left">Our dedication to innovation is not only about pushing the boundaries of what is possible in wireless systems, but also about creating practical solutions that drive real-world impact. By fostering an environment of creativity and technical excellence, we aim to lead the way in technological progress and deliver advancements that shape the future of our industry. Through our ongoing research and development initiatives, we are committed to advancing technology, solving complex problems, and contributing to a more connected and efficient world.</p>
                    </div>
                    <img src={lights} className="img-fluid" style={{width: "100%", height: "auto"}} alt="lights"/>
                </div>
            </div>
        </div>
    )
}

export default Approach;