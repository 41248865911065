import React, { useEffect } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import Home from './Open6G/Home';
import Services from './Open6G/Services';
import OTIC from './Open6G/OTIC';
import Research from './Open6G/Research';
import Navigation from './Open6G/Navigation';
import Footer from './Open6G/Footer';
import ScrollToTop from './Open6G/Components/ScrollToTop';
import './App.css';
import './styles.css';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const links = [
    { label: "Home", title: "HOME" },
    { label: "OTIC", title: "OTIC" },
    { label: "Services", title: "SERVICES" },
    { label: "Research", title: "RESEARCH" }
];
  
  return (
    <div>
      <HashRouter>
        <div>
          <ScrollToTop/>
          <Navigation/>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/Home/*" element={<Home/>} />
            <Route path="/OTIC/*" element={<OTIC/>} />
            <Route path="/Services/*" element={<Services/>} />
            <Route path="/Research/*" element={<Research/>} />
          </Routes>
          <Footer/>
        </div>
      </HashRouter>
    </div>
  );
}

export default App;
