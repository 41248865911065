import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import SectionHeader from "../Components/SectionHeader";

function Partners() {
    return (
        <div>
            <SectionHeader text="PARTNERS"></SectionHeader>
            <div className="section">
                <div className="partners-container">
                    <div className="partners-row">
                        <h3 className="partners-header">STRATEGIC PARTNERS</h3>
                        <div className="partners-block-top">
                            <Link to="https://www.amplitechgroup.com/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/amplitech.png" className="img-fluid p-img-dell no-border-radius" alt="amplitech"/>
                            </Link>
                            <Link to="https://www.dell.com/en-us" target="_blank" rel="noopener noreferrer">
                                <img src="/images/dell.png" className="img-fluid p-img-dell no-border-radius" alt="dell"/>
                            </Link>
                            <Link to="https://www.interdigital.com/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/interdigital.png" className="img-fluid p-img no-border-radius" alt="interdigital"/>
                            </Link>
                            <Link to="https://www.keysight.com/us/en/home.html" target="_blank" rel="noopener noreferrer">
                                <img src="/images/keysight.png" className="img-fluid p-img no-border-radius" alt="keysight"/>
                            </Link>
                            <Link to="https://www.mavenir.com/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/mavenir.png" className="img-fluid p-img-mavenir no-border-radius" alt="mavenir"/>
                            </Link>
                            <Link to="https://www.mitre.org/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/mitre.png" className="img-fluid p-img-mitre no-border-radius" alt="mitre"/>
                            </Link>
                            <Link to="https://www.nvidia.com/en-us/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/nvidia.png" className="img-fluid p-img-nvidia no-border-radius" alt="nvidia"/>
                            </Link>
                            <Link to="https://www.qualcomm.com/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/qualcomm.png" className="img-fluid p-img-qualcomm no-border-radius" alt="qualcomm"/>
                            </Link>
                            <Link to="https://laboratories.telekom.com" target="_blank" rel="noopener noreferrer">
                                <img src="/images/t-mobile.png" className="img-fluid p-img no-border-radius" alt="t-mobile"/>
                            </Link>
                            <Link to="https://www.tii.ae/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/tii.png" className="img-fluid p-img-dell no-border-radius" alt="tii"/>
                            </Link>
                        </div>
                    </div>
                    <div className="partners-row">
                        <h3 className="partners-header">INDUSTRY PARTNERS</h3>
                        <div className="partners-block">
                            <Link to="https://www.att.com/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/at&t.png" className="img-fluid p-img-dell no-border-radius" alt="at&t"/>
                            </Link>
                            <Link to="https://www.etri.re.kr/eng/main/main.etri" target="_blank" rel="noopener noreferrer">
                                <img src="/images/etri.png" className="img-fluid p-img no-border-radius" alt="etri"/>
                            </Link>
                            <Link to="https://www.fujitsu.com/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/fujitsu.png" className="img-fluid p-img no-border-radius" alt="fujitsu"/>
                            </Link>
                            <Link to="https://www.intel.com/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/intel.png" className="img-fluid p-img-mavenir no-border-radius" alt="intel"/>
                            </Link>
                            <Link to="https://www.mathworks.com/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/mathworks.png" className="img-fluid p-img no-border-radius" alt="mathworks"/>
                            </Link>
                            <Link to="https://www.redhat.com/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/redhat.png" className="img-fluid p-img-dell no-border-radius" alt="redhat"/>
                            </Link>
                            <Link to="https://www.rtx.com/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/raytheon.png" className="img-fluid p-img-raytheon no-border-radius" alt="raytheon"/>
                            </Link>
                            <Link to="https://www.viavisolutions.com/en-us" target="_blank" rel="noopener noreferrer">
                                <img src="/images/viavi.png" className="img-fluid p-img-qualcomm no-border-radius" alt="viavi"/>
                            </Link>
                        </div>
                    </div>
                    <div className="partners-row">
                        <h3 className="partners-header">SMALL BUSINESSES</h3>
                        <div className="partners-block">
                            <Link to="https://a5gnet.com/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/a5g.png" className="img-fluid p-img-dell no-border-radius" alt="a5g"/>
                            </Link>
                            <Link to="https://airanaculus.com/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/airanaculus.png" className="img-fluid p-img no-border-radius" alt="airanaculus"/>
                            </Link>
                            <Link to="https://www.androcs.com/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/andro.png" className="img-fluid p-img-raytheon no-border-radius" alt="andro"/>
                            </Link>
                            <Link to="https://www.bionetsonar.com/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/bionet.png" className="img-fluid p-img-dell no-border-radius" alt="bionet"/>
                            </Link>
                            <Link to="https://www.episci.com/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/episci.png" className="img-fluid p-img-raytheon no-border-radius" alt="episci"/>
                            </Link>
                            <Link to="https://www.us-ignite.org/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/usignite.png" className="img-fluid p-img no-border-radius" alt="usignite"/>
                            </Link>
                            <Link to="https://nexcepta.com/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/nexcepta.png" className="img-fluid p-img-nexcepta no-border-radius" alt="nexcepta"/>
                            </Link>
                            <Link to="https://www.qylur.com/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/qylur.png" className="img-fluid p-img-raytheon no-border-radius" alt="qylur"/>
                            </Link>
                            <Link to="https://ztouchnet.com/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/icons/ztouch.svg" className="img-fluid p-img no-border-radius" alt="ztouch"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Partners;
