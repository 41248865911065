import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import SystemIntegration from "./SystemIntegration";
import Benefits from "./Benefits";
import RequestBooking from "../Components/RequestBooking";
import "./index.css";

function Services() {
    const { hash } = useLocation();

    window.scrollTo(0, 0);

    useEffect(() => {      
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);

    return (
        <div style={{paddingTop: "3.612rem"}}>
            <div className="about-container">
                <div className="left-container">
                    <img src="/images/lights.jpg" className="img-fluid no-border-radius" id="lights" alt="colosseum" />
                    <div className="about-overlay">
                        <h2>OPEN6G SYSTEM INTEGRATION</h2> 
                        {/*<p>The North American OTIC in the Boston area (Open6G OTIC) builds on testbeds and facilities hosted in the Open6G space of the Institute for the Wireless Internet of Things (WloT) at Northeastern University, member of the O-RAN Alliance since 2020. The Open6G OTIC is a one-stop shop for Open RAN innovation. It serves as a hub for the development and testing of next-generation wireless networks utilizing Open RAN technology and Artificial Intelligence, offering a comprehensive range of testing services that facilitates compliance, interoperability, performance, and functional testing for Open RAN products.</p>*/}
                        <p>Open6G at Northeastern University provides system integration services to design, optimize, and deploy end-to-end systems and network based on Open RAN technologies. Working with Open6G partners and spinoffs, we offer a complete package, from design to on-site provisioning and configuration.</p>
                    </div>
                </div>
                <div className="right-container-mod">
                    {/*<h2>OPEN6G: ONE-STOP SHOP FOR OPEN RAN INNOVATION</h2>*/}
                    <svg width="100%" height="0.1" viewBox="0 0 427 0.1" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
                        <line y1="0.05" x1="0" x2="100%" y2="0.05" stroke="#FFFFFF"/>
                    </svg>
                    <div className="top-icon-row">
                        <img src="/images/plug.png" alt="plug" style={{maxHeight: "2.5rem", width: "auto"}}/>
                        <h4>Interoperability</h4>
                    </div>
                    <div className="top-icon-row cc-row">
                        <img src="images/setting.png" alt="settings" style={{maxHeight: "2.5rem", width: "auto"}}/>
                        <h4>Customization & Configuration</h4>
                    </div>
                    <div className="top-icon-row">
                        <img src="images/loop.png" alt="loop" style={{maxHeight: "2.5rem", width: "auto"}}/>
                        <h4>Performance Optimization</h4>
                    </div>
                    <div className="top-icon-row eeo-row">
                        <img src="images/leaf.png" className="no-border-radius" alt="leaf" style={{maxHeight: "2.5rem", width: "auto"}}/>
                        <h4>Energy Efficiency Optimization</h4>
                    </div>
                    <div className="top-icon-row">
                        <img src="images/document.png" alt="document" style={{maxHeight: "2.5rem", width: "auto"}}/>
                        <h4>Training</h4>
                    </div>
                    <svg width="100%" height="0.1" viewBox="0 0 427 0.1" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
                        <line y1="0.05" x1="0" x2="100%" y2="0.05" stroke="#FFFFFF"/>
                    </svg>
                </div>
            </div>
            <div style={{position: "relative"}}>
                <div id="SystemIntegration">
                    <SystemIntegration/>
                </div>
                <div id="Benefits">
                    <Benefits/>
                </div>
                <div id="RequestBooking">
                    <RequestBooking/>
                </div>
            </div>
        </div>
    );
}

export default Services;