import React from 'react';
import "./index.css";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export default class Modal extends React.Component<ModalProps> {
  render() {
    const { isOpen, onClose, children } = this.props;

    if (!isOpen) return null;

    const handleOverlayClick = (e: React.MouseEvent) => {
      if (e.target instanceof HTMLElement && e.target.classList.contains('modal-overlay')) {
        onClose();
      }
    };

    return (
      <div className="modal-overlay" onClick={handleOverlayClick}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <button className="modal-close-button" onClick={onClose}>
            &times;
          </button>
          {children}
        </div>
      </div>
    );
  }
}