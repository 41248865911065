import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./index.css";
import Menu from "./Menu";
import Certifications from "./Certifications";
import Plugfests from "./Plugfests";
import Testimonials from "./Testimonials";
import Equipment from "./Equipment";
import RequestBooking from "../Components/RequestBooking";

function OTIC() {
    const { hash } = useLocation();

    window.scrollTo(0, 0);

    useEffect(() => {      
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);

    return (
        <div className="home-top">    
            <div className="full-height">
                <div className="image-container">
                    <img className="img-fluid no-border-radius" src="/images/colosseum-stretched.jpg" id="col-2-pic" alt="colosseum" />
                    <div className="overlay">
                        <h1>OPEN6G OTIC</h1>
                        <div className="overlay-text">
                            <h4>O-RAN North American OTIC in the Boston Area</h4>
                            <p>The Open6G Open Testing and Integration Center (OTIC) is a hub for developing and testing next-generation wireless networks based on Open RAN. The OTIC was approved by the O-RAN Alliance as North American OTIC in the Boston Area (Northeastern University) in May 2023.</p>
                            <p>
                                The Open6G OTIC provides testing services for Open RAN products, including compliance, interoperability, performance, and functional testing against multiple state-of-the-art environments – from real&nbsp;
                                <Link to="https://www.prnewswire.com/news-releases/northeastern-university-launches-fully-automated-and-virtualized-o-ran-private-5g-network-with-ai-automation-301799522.html"
                                target="_blank" rel="noopener noreferrer">
                                    <div className="home-top-link">
                                        <p className="subtitle">5G RANs</p>
                                    </div>
                                </Link>&nbsp;to emulated networks in&nbsp;
                                <Link to="https://www.northeastern.edu/colosseum/"
                                target="_blank" rel="noopener noreferrer">
                                    <div className="home-top-link">
                                        <p className="subtitle">Colosseum</p>
                                    </div>
                                </Link>&nbsp;and RAN/RIC emulators.
                            </p>
                        </div>
                    </div>
                </div>
                <Menu/>
            </div>
            <div className="home-rest">
                <div id="Certifications">
                    <Certifications/>
                </div>
                <div id="Testimonials">
                    <Testimonials/>
                </div>
                <div id="Equipment">
                    <Equipment/>
                </div>
                <div id="Plugfests">
                    <Plugfests/>
                </div>
                <div id="RequestBooking">
                    <RequestBooking/>
                </div>
            </div>
        </div>
    );
}

export default OTIC;