import React from "react";
import "./index.css";
import SectionHeader from "../Components/SectionHeader";

function FocusAreas() {
    return (
        <div className="reduce-top-later">
            <SectionHeader text="FOCUS AREAS"></SectionHeader>
            <div className="section">
                <div className="areas-container">
                    <div className="group-overlay">
                        <div className="icon-row">
                            <img src="/images/layer.png" className="icon" alt="background"/>
                            <h4 id="left">END-TO-END FULL STACK SYSTEMS</h4>
                        </div>
                        <p id="left">We develop and test full protocol stacks in both emulated and real-world environments. This ensures thorough evaluation and validation of Open RAN solutions across diverse scenarios guaranteeing robust performance and interoperability in varying deployment conditions.</p>
                    </div>
                    <div className="group-overlay">
                        <div className="icon-row">
                            <img src="/images/ai.png" className="icon" alt="background"/>
                            <h4 id="left">INTEROPERABLE AI-DRIVEN NETWORKS</h4>
                        </div>
                        <p id="left">We focus on enabling intelligent networks through the application of AI techniques to network optimization, orchestration, and operations. We demonstrate real-world gains by testing AI across emulated and real-world testbeds, and have extensive data collection, training, and AI/ML evaluation capabilities.</p>
                    </div>
                    <div className="group-overlay">
                        <div className="icon-row">
                            <img src="/images/digital-twin.png" className="icon" alt="background"/>
                            <h4 id="left">OPEN RAN DIGITAL TWIN</h4>
                        </div>
                        <p id="left">Our digital twinning process accurately emulates real-world scenarios with high fidelity in the Colosseum Open RAN Digital Twin. Combining real-world over-the-air testbeds in an FCC Innovation Zone with the Colosseum Digital Twin facilitates comprehensive evaluation and testing of Open RAN deployments.</p>
                    </div>
                    <div className="group-overlay">
                        <div className="icon-row">
                            <img src="/images/leaf.png" className="leaf-icon no-border-radius" alt="background"/>
                            <h4 className="padded-area" id="left">ENERGY EFFICIENT NETWORKS</h4>
                        </div>
                        <p id="left">We prioritize energy efficiency in Open RAN deployments, going beyond traditional techniques to develop and evaluate software-based energy efficiency wireless systems. This comprehensive approach ensures that both hardware and software components contribute to sustainable and efficient network operations.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FocusAreas;