import SectionHeader from "../Components/SectionHeader";
import LearnMore from "../Components/LearnMore";
import { Link } from "react-router-dom";
import Card from "../Components/Card";


function ResearchOpen6G() {
    return (
        <div className="reduce-top-later">
            {/*OpenRAN Gym, NOFO awards, xApps/rApps, dApps, prototypes, systems*/}
            <SectionHeader text="OPEN6G RESEARCH & DEVELOPMENT ACHIEVEMENTS"></SectionHeader>
            {/*<div className="section">
                <div className="research-container">
                    <div style={{display: "flex", flexDirection: "column", gap: "1rem", alignItems: "flex-start"}}>
                        <p id="left">The Open6G center is home to world-leading research on <b>end-to-end, programmable, intelligent, and softwarized Open RAN systems</b>. The team at Open6G has demonstrated for the first time the gain that dynamic slicing and load-balancing driven by DRL agents on O-RAN xApps. The team has also shown key results in the areas of intelligent spectrum sensing and sharing, real-time extensions of the O-RAN architecture, and intelligent control and orchestration for Open RAN.</p>
                    </div>
                    <img src="/images/wire-display.png" className="img-fluid" style={{width: "100%", height: "auto"}} alt="lights"/>
                </div>
            </div>*/}
            <div className="section" id="research-achievements">
                <div className="component-container">
                    <div className="component" id="tenoran">
                        <Link to="https://www.ntia.gov" target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Card>
                                <img src="/images/engineering.png" className="img-fluid c-pic" alt="person in colosseum"/>
                                <div className="component-text">
                                    <h4 id="left">Energy Efficiency</h4>
                                    <p id="left">Efficient processing of information is key to advancing software-driven open and programmable networks. With the TENORAN NTIA award, led by Dr. Salvatore D’Oro, the Open6G team advances 5G (and beyond) testing capabilities with a fine-grained energy consumption evaluation for Open RAN systems, thus contributing to sustainability efforts, and identify repeatable and programmatic testing procedures and functionalities to support multi-vendor and vertical energy efficiency optimization.</p>
                                    <LearnMore/>
                                </div>
                            </Card>
                        </Link>
                    </div>
                    <div className="component" id="digiran">
                        <Link to="https://www.ntia.gov" target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Card>
                                <img src="/images/hand-wires.png" className="img-fluid c-pic" alt="person in colosseum"/>
                                <div className="component-text">
                                    <h4 id="left">Digital Twinning</h4>
                                    <p id="left">Within the scope of the DigiRAN NTIA award, the Open6G team proposes new methodologies to develop high-fidelity and real-time Digital Twins of Wireless Networks. DigiRAN, led by Dr. Pedram Johari, will leverage Northeastern's Colosseum wireless network emulator to streamline end-to-end testing and evaluation of the performance and interoperability of Open Radio Access Networks.</p>
                                    <LearnMore/>
                                </div>
                            </Card>
                        </Link>
                    </div>
                    <div className="component" id="autoran">
                        <Link to="https://www.ntia.gov" target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Card>
                                <img src="/images/person-wires.png" className="img-fluid c-pic" alt="person in colosseum"/>
                                <div className="component-text">
                                    <h4 id="left">End-to-End Automation </h4>
                                    <p id="left">Through the AutoRAN NTIA award, the Open6G team tackles one of the fundamental issues that could styme the momentum around Open RAN system: the need to integrate and test components of 5G base stations from multiple vendors. This is key to building a robust and innovative supply chain, but at the same time is often associated with costly and labor-intensive processes. AutoRAN, led by Dr. Michele Polese, leverages our expertise in automation and AI to streamline this process, so that validation in a multi-vendor environment becomes a plug-and-play process.</p>
                                    <LearnMore/>
                                </div>
                            </Card>
                        </Link>
                    </div>
                    <div className="component" id="ousd">
                        <Link to="https://www.ntia.gov" target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Card>
                                <img src="/images/open6g-stack-simple.png" className="img-fluid c-pic" alt="person in colosseum"/>
                                <div className="component-text">
                                    <h4 id="left">End-to-End Protocol Stacks</h4>
                                    <p id="left">The Open6G team designs, contribute to, integrates, and maintains open and open-source protocol stacks for 5G and beyond. Our end-to-end systems include RAN, core, RAN Intelligent Controllers, and automation elements necessary to deploy and manage Open RAN systems and develop innovative research on algorithms and optimization for wireless networks. This effort is led by an anchor award from the OUSD R&E FutureG Office.</p>
                                    <LearnMore/>
                                </div>
                            </Card>
                        </Link>
                    </div>
                    <div className="component" id="oran">
                        <Link to="https://www.openrangym.com" target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Card>
                                <img src="/images/o-ran-loops.png" className="img-fluid c-pic" alt="person in colosseum"/>
                                <div className="component-text">
                                    <h4 id="left">Intelligent O-RAN Control</h4>
                                    <p id="left">Programmability and closed-loop control enable energy optimization within the RAN, support for diverse traffic profiles with conflicting requirements through dynamic slicing, and dynamic load balancing of users across the network, among other benefits. We have developed xApps, rApps, and their real-time extension, dApps, for intelligent control and optimization of the RAN with data-driven approaches.</p>
                                    <LearnMore/>
                                </div>
                            </Card>
                        </Link>
                    </div>
                </div>
            </div>
            {/* <SectionHeader text="RESEARCH & DEVELOPMENT APPROACH"></SectionHeader>
            <div className="section">
                <div className="research-container">
                    <div style={{display: "flex", flexDirection: "column", gap: "1rem", alignItems: "flex-start"}}>
                        <p id="left">Our research and development efforts are at the forefront of technological advancement, driven by a commitment to exploring and harnessing the latest innovations in science and engineering. We investigate cutting-edge technologies and emerging trends, focusing on how these can be transformed into <b>practical, real-world solutions for next-generation wireless systems</b>. Our approach is rooted in fundamental and algorithmic research that transition to systems and prototyping as solutions mature.</p>
                        <p id="left">We strive to translate complex ideas into tangible applications and systems that enhance operational efficiency and address pressing challenges in wireless systems. Our team of skilled researchers, engineers, and developers works collaboratively to refine these concepts through a series of iterative tests and validations, ensuring that each solution meets the highest standards of performance and quality.</p>
                        <p id="left">In addition to our internal efforts, we actively engage with <Link to="/OTIC/#Partners" style={{color: "var(--color-accent-dark)"}}>industry partners and stakeholders</Link> to gain insights and feedback, which helps us to continuously improve and adapt our technologies. This collaborative approach allows us to stay ahead of industry trends and contribute to the development of new standards and best practices.</p>
                        <p id="left">Our dedication to innovation is not only about pushing the boundaries of what is possible in wireless systems, but also about creating practical solutions that drive real-world impact. By fostering an environment of creativity and technical excellence, we aim to lead the way in technological progress and deliver advancements that shape the future of our industry. Through our ongoing research and development initiatives, we are committed to advancing technology, solving complex problems, and contributing to a more connected and efficient world.</p>
                    </div>
                    <img src={lights} className="img-fluid" style={{width: "100%", height: "auto"}} alt="lights"/>
                </div>
            </div> */}
        </div>
    );
}

export default ResearchOpen6G;