import React from "react";
import { Link } from "react-router-dom";
import SectionHeader from "../Components/SectionHeader";
import Button from "../Components/Button";
import "./index.css";

function Testimonials() {
    return (
        <div>
            <SectionHeader text="CLIENT TESTIMONIALS & CASE STUDIES"></SectionHeader>
            <div className="section">
                <p style={{paddingBottom: "6rem", textAlign: "left"}}>Explore our client testimonials and case studies to see how Open6G OTIC collaborates with industry leaders on cutting-edge Open RAN projects. Each testimonial offers insights into our work, from optimizing network performance with AI to ensuring seamless interoperability. Click to learn more about the specific challenges we've tackled and the impactful results achieved.</p>
                <div className="testimonials">
                    <div className="odd-testimonial-row">
                        <div className="company">
                            <h4>AT&T and Verizon ACCoRD</h4>
                            <p id="left">The Open6G Open Testing and Integration Center (OTIC) at the Institute for the Wireless Internet of Things (WIoT) at Northeastern University will partner with AT&T and Verizon as a neutral testing facility in the Acceleration of Compatibility and Commercialization for Open RAN Deployments Consortium (ACCoRD) framework. ACCoRD has been selected to receive $42.3M in funding from the U.S. National Telecommunications and Information Administration (NTIA) through the Public Wireless Innovation Fund.</p>
                            <Link to="https://www.prnewswire.com/news-releases/northeastern-university-and-open-6g-otic-partners-with-att-and-verizon-to-advance-open-and-interoperable-cellular-networks-in-ntia-te-award-302061937.html" 
                            target="_blank" rel="noopener noreferrer">
                                <Button buttonType="button-primary" textStyle="subtitle">READ MORE</Button>
                            </Link>
                        </div>
                        <div className="image-place logo-container">
                            <Link to="https://www.att.com/?source=EC1NTmmlp0000000P&tfn=homesolutions&WT.srch=1&wtExtndSource=at%26t&ds_eid=700000002453638&ds_cid=71700000089758987&ds_agid=58700007591081822&ds_kids=p68533646556&gclsrc=aw.ds&&gclid=CjwKCAjwreW2BhBhEiwAavLwfMpAm-cLYjBenQDx2zZsjUtfW0lXfZNLDYPBMQ3o9N8BPJZs2q2AoxoC3-EQAvD_BwE&gclsrc=aw.ds"
                            target="_blank" rel="noopener noreferrer">
                                <img src="/images/at&t.png" className="img-fluid" style={{height: "auto%", width: "100%"}} alt="at&t"/>
                            </Link>
                            <Link to="https://www.verizon.com/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/verizon.png" className="img-fluid" style={{height: "auto%", width: "100%"}} alt="verizon"/>
                            </Link>
                        </div>
                    </div>
                    <div className="even-testimonial-row">
                        <Link to="https://https://www.techmahindra.com/" target="_blank" rel="noopener noreferrer" >
                            <div className="image-place">
                                <img src="/images/techm_logo.svg" className="img-fluid full-image" alt="techmahindra"/>
                            </div>
                        </Link>
                        <div className="company">
                            <h4>Tech Mahindra</h4>
                            <p id="left">Manish Mangal, Chief Technology Officer and Telecom & Global Business Head of Network Services at Tech Mahindra, said, "Today, we start a journey of innovation towards a connected future through this MoU with Northeastern University. By combining our expertise, we will create new technology, focusing on testing and verification, enabling our customers to scale at speed. Together, we will advance Open RAN and 6G, transforming ideas into reality and delivering exceptional value." This collaboration will serve as a trusted avenue for industry ORAN vendors, Communication Service Providers (CSPs), and start-ups to transform their new concepts and ideas into reality by utilizing artificial intelligence and ORAN technology. It will provide a collaborative, vendor-independent environment where customers can test their products and solutions against ORAN specifications. Additionally, it will enable the vendors to evaluate network energy efficiency in ORAN deployments and ensure that their products meet the highest standards of performance, security, and interoperability."</p>
                            <Link to="https://www.techmahindra.com/insights/press-releases/tech-mahindra-and-northeastern-university-collaborate-drive-innovation-oran/" 
                            target="_blank" rel="noopener noreferrer" >
                                <Button buttonType="button-primary" textStyle="subtitle">READ MORE</Button>
                            </Link>
                        </div>
                    </div>
                    <div className="odd-testimonial-row">
                        <div className="company">
                            <h4>Keysight Technologies</h4>
                            <p id="left">“This partnership underscores Keysight’s commitment to fostering O-RAN innovation and supporting industry and academia institutions such as Northeastern University to advance the Open RAN ecosystem with our world-class test solutions,” says Peng Cao, Vice President and General Manager at Keysight’s Wireless Test Group. “As an industry leader, we are pleased to contribute to Northeastern Open6G OTIC’s efforts in accelerating the Open RAN ecosystem by ensuring the reliability and performance of next-generation wireless networks.”</p>
                            {/*<p id="left">“Working closely with Open6G OTIC has revolutionized our testing and integration processes. Their advanced capabilities in compliance, functional testing, and real-world scenario simulations have enabled us to validate our products with precision and efficiency. Their collaborative approach and dedication to innovation have positioned Open6G OTIC as an invaluable partner in our mission to deliver high-performance solutions that meet the evolving needs of our global customers."</p>*/}
                            <Link to="https://www.prnewswire.com/news-releases/the-open-6g-otic-at-northeastern-university-announces-general-availability-of-open-ran-testing-and-integration-solutions-302144949.html" 
                            target="_blank" rel="noopener noreferrer" >
                                <Button buttonType="button-primary" textStyle="subtitle">READ MORE</Button>
                            </Link>
                        </div>
                        <Link to="https://www.keysight.com/us/en/home.html" target="_blank" rel="noopener noreferrer" >
                            <div className="image-place">
                                <img src="/images/icons/keysight-test.svg" className="img-fluid full-image" alt="keysight"/>
                            </div>
                        </Link>
                    </div>
                    <div className="even-testimonial-row">
                        <Link to="https://www.amplitechgroup.com/" target="_blank" rel="noopener noreferrer" >
                            <div className="image-place">
                                <img src="/images/amplitech.png" className="img-fluid full-image" alt="amplitech"/>
                            </div>
                        </Link>
                        <div className="company">
                            <h4>AmpliTech</h4>
                            <p id="left">Fawad Maqbool, CEO of AmpliTech Group, stated, "This is yet another major accomplishment in our constant push to bring 5G solutions to the industry. We are proudly teaming up with the Northeastern University Open6G OTIC that provides the broadest set of unparalleled expertise and capabilities in 5G and O-RAN to conduct this key interoperability testing of our radios. Our collaboration extends beyond research, aiming to deliver practical end-to-end systems to MNOs, thereby making O-RAN adoption acceptable to key industry players."</p>
                            <Link to="https://www.prnewswire.com/news-releases/open6g-otic-at-northeastern-university-to-perform-o-ran-5g-interoperability-testing-and-end-to-end-demonstration-of-amplitechs-o-ran-cat-b-64t64r-mimo-radio-302107308.html" 
                            target="_blank" rel="noopener noreferrer" >
                                <Button buttonType="button-primary" textStyle="subtitle">READ MORE</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Testimonials; 