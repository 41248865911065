import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import SectionHeader from "../Components/SectionHeader";

function Locations() {
    return (
        <div>
            <SectionHeader text="LOCATIONS"></SectionHeader>
            <div className="section">
                <div className="location-section">
                    <div className="location-container" style={{alignItems: "center"}}>
                        <img src="/images/boston.jpg" className="img-fluid location-image" alt="boston location"/>
                        <h3 style={{paddingBottom: "1rem"}}>BOSTON</h3>
                        <Link to="https://www.google.com/maps/place/805+Columbus+Ave,+Boston,+MA+02120/@42.3377088,-71.0895858,17z/data=!3m1!4b1!4m6!3m5!1s0x89e37a22a3a9ffa3:0x4fc7d96616e5f289!8m2!3d42.3377049!4d-71.0870109!16s%2Fg%2F11cnddhs8b?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D"
                        target="_blank" rel="noopener noreferrer"
                        style={{textDecoration: "none", color: "black"}}>
                            <p className="subtitle location-link">815 Columbus Ave, Boston MA 02120</p>
                        </Link>
                    </div>
                    <div className="location-container" style={{alignItems: "center"}}>
                        <img src="/images/burlington.jpg" className="img-fluid location-image" alt="burlington location"/>
                        <h3 style={{paddingBottom: "1rem"}}>BURLINGTON</h3>
                        <Link to="https://www.google.com/maps/place/147+S+Bedford+St+Building+5,+Burlington,+MA+01803/@42.4778283,-71.1953377,17z/data=!3m1!4b1!4m5!3m4!1s0x89e39e2785e23263:0xa0f369a83f59d436!8m2!3d42.4778244!4d-71.1927628!5m1!1e1?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D"
                        target="_blank" rel="noopener noreferrer"
                        style={{textDecoration: "none", color: "black"}}>
                            <p className="subtitle location-link">147 S Bedford St, Building 5, Burlington, MA 01803</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Locations;