import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./index.css";

function Navigation() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const links = [
        { label: "Home", title: "HOME" },
        { label: "OTIC", title: "OTIC" },
        { label: "Services", title: "SERVICES" },
        { label: "Research", title: "RESEARCH" }
    ];

    const { pathname } = useLocation();

    return (
        <div className="nav-container">
            <ul className="nav-bar-left">
                <li>
                    <Link to="/Home" className="nav-link">
                        <img src="/images/icons/open6g.svg" className="img-fluid logo no-border-radius" alt="new logo"/>
                    </Link>
                </li>
            </ul>
            <ul className="nav-bar-right">
                {links.map((link, index) => (
                    <li key={index} className={(pathname === `/${link.label}` || (pathname === "/" && link.label === "Home")) ? "active" : ""}>
                        <Link to={`/${link.label}`} className="nav-link">
                            <h6 style={{color: "black"}}>{link.title}</h6>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Navigation;
