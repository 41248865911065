import React from "react";
import { Link } from "react-router-dom";
import SectionHeader from "../Components/SectionHeader";
import LearnMore from "../Components/LearnMore";
import Card from "../Components/Card";
import "./index.css";

function KeyComponents() {
    return (
        <div>
            <SectionHeader text="OPEN6G CORE COMPETENCIES"></SectionHeader>
            <div className="section">
                <div className="component-container">
                    <div className="component">
                        <Link to="https://www.northeastern.edu/colosseum/" target="_blank" rel="noopener noreferrer" 
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Card>
                                <img src="/images/colosseum-person.png" className="img-fluid c-pic" alt="person in colosseum"/>
                                <div className="component-text">
                                    <h4 id="left">Colosseum</h4>
                                    <p id="left">Colosseum, the world’s largest wireless network emulator with hardware-in-the-loop, with 256 software-defined radios, more than 20 racks of compute, with programmable GPUs, FPGAs, and components to orchestrate and manage a multi-tenant O-RAN compliant experimental environment.</p>
                                    <LearnMore/>
                                </div>
                            </Card>
                        </Link>
                    </div>
                    <div className="component">
                        <Link to="https://openrangym.com" target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Card>
                                <img src="/images/hand-wires.png" className="img-fluid c-pic" alt="hand with wires"/>
                                <div className="component-text">
                                    <h4 id="left">Open RAN Gym</h4>
                                    <p id="left">The OpenRAN Gym framework, a large-scale data collection framework for the training of AI/ML, hosts multiple 5G protocol stacks for RAN and core and tests a mix of commercial radios and emulated devices.</p>
                                    <LearnMore/> 
                                </div>
                            </Card>
                        </Link>      
                    </div>
                    <div className="component">
                        <Link to="https://x5g.org/" target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Card>
                                <img src="/images/isec-offices.png" className="img-fluid c-pic" alt="isec offices"/>
                                <div className="component-text">
                                    <h4 id="left">Diverse 5G RAN Deployments</h4>
                                    <p id="left">Multiple 5G RAN deployments and equipment, including softwarized and commercial RAN products, 5G UEs, core networks, a programmable 5G and O-RAN testbed with 10+ gNodeBs on the indoor Arena testbed, and testing equipment.</p>
                                    <LearnMore/>
                                </div>
                            </Card>
                        </Link>
                    </div>
                    <div className="component">
                        <Link to="https://wiot.northeastern.edu/facilities/fcc-innovation-zone/" target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Card>
                                <img src="/images/person-wires.png" className="img-fluid c-pic" alt="person with wires"/>
                                <div className="component-text">
                                    <h4 id="left">FCC Innovation Zone Framework</h4>
                                    <p id="left">One of the four FCC Innovation Zone frameworks in the nation, which allows the lab to quickly obtain experimental licenses for over-the-air testing in the Northeastern University Innovation Campus in Burlington, MA, and in the main Boston, MA campus.</p>
                                    <LearnMore/>
                                </div>
                            </Card>
                        </Link>
                    </div>
                    <div className="component">
                        <Link to="https://nupdresarchive.wpenginepowered.com/ecuas-lab/" target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Card>
                                <img src="/images/blue-center.png" className="img-fluid c-pic" alt="center with blue floor"/>
                                <div className="component-text">
                                    <h4 id="left">UAV Testing Facility</h4>
                                    <p id="left">An outdoor UAV testing facility to profile use cases related to drone mobility and a large anechoic chamber to test radio devices in an interference-free environment.</p>
                                    <LearnMore/>
                                </div>
                            </Card>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default KeyComponents;
